<template>
     <v-col cols="12" class="mt-6 pt-6">
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-btn class="mb-2" color="warning" @click="dialogSede=true">
            <v-icon >mdi-plus</v-icon> AGREGAR SEDE
        </v-btn>
        <v-btn class="mb-2 ml-6" color="primary" @click="dialog=true">
            <v-icon >mdi-file-image</v-icon> VER REFERENCIA
        </v-btn>
        <v-expansion-panels>
            <v-expansion-panel
                v-for="(item,i) in sedes"
                :key="i"
            >
            <v-expansion-panel-header> Sede {{ item.sedeName }} </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-btn 
                    color="primary" 
                    small
                    @click="addService(item,i)"
                >
                    Agregar servicio
                </v-btn>
                <v-btn 
                    color="warning" 
                    small
                    @click="delteSede(item,i)"
                    class="ml-2"
                >
                    Eliminar sede
                </v-btn>
                <v-form 
                    ref="form_sedes"
                    v-for="(service, x) in item.services"
                    :key="x"
                >
                    <v-col>
                        <v-row>
                            <v-col cols="12" md="4" >
                                <v-file-input
                                    label="Logo servicio 213px X 213px"
                                    dense
                                    clearable
                                    accept=".jpg,.png,.JPG,.PNG"
                                    prepend-icon=""
                                    outline
                                    @change="setFile($event,i,x)"
                                    :rules="service.content_id != null ? [] : requiredRule"
                                />
                                <a target="_blank" :href="`${domain}img/web_commerce/${service.sedeImg}`">{{service.sedeImg}}</a>
                            </v-col>
                            <v-col cols="12" md="4" >
                                <v-text-field
                                    label="Nombre del servicio"
                                    v-model="service.service"
                                    dense
                                    :rules=requiredRule
                                    outline
                                />
                            </v-col>
                            <v-col cols="12" md="4" >
                                <v-text-field
                                    label="Url de la sede del google maps"
                                    v-model="service.sedeUrlMaps"
                                    dense
                                    :rules=requiredRule
                                    outline
                                />
                            </v-col>
                            <tiptap-vuetify 
                                style="width:100%"
                                v-model="service.sedeDescription" 
                                :extensions="extensions"
                                placeholder="ESCRIBE LA DESCRIPCIÓN DE LA SEDE"
                                :rules=requiredRule
                            />
                        </v-row>
                        <v-col cols="12" class="pt-6 text-center">
                            <v-btn
                                color="primary"
                                class="mx-2"
                                @click="storeDataSede(service,x)"
                                :loading="loadingBtn"
                            >  <v-icon>mdi-floppy</v-icon> Guardar
                            </v-btn>
                            <v-btn
                                color="warning"
                                class="mx-2"
                                @click="deleteDataSede(service,i,x)"
                                :loading="loadingBtn"
                            >  <v-icon>mdi-delete</v-icon> ELIMINAR
                            </v-btn>
                        </v-col>
                    </v-col>
                </v-form>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog v-model="dialog" persistent>
            <v-img :src="require('@/assets/images/sedes.png')" />
            <v-btn block @click="dialog=false">CERRAR</v-btn>
        </v-dialog>
        <v-dialog
            v-model="dialogSede" 
            persistent
            width="400px"
        >
            <v-card>
				<v-card-title>
				    <span class="headline">Crear sede</span>
				</v-card-title>
				<v-form ref="form_files">
					<v-card-text class="pb-0">
					<v-container fluid>
						<v-row class="py-0">
                            <v-col cols="12" class="py-0" >
                                <v-text-field
                                    v-model="sede"
                                    dense
                                    :rules=requiredRule
                                    outline
                                />
                            </v-col>
						</v-row>
					</v-container>
					</v-card-text>
                    <v-col cols="12" class="pt-0 text-center">
                        <v-btn
                            color="primary"
                            class="mx-2"
                            @click="storeSede"
                            :loading="loadingBtn"
                        >  <v-icon>mdi-floppy</v-icon> Guardar
                        </v-btn>
                        <v-btn
                            color="secondary"
                            class="mx-2"
                            
                            @click="close"
                        > <v-icon>mdi-cancel</v-icon> Cancelar
                        </v-btn>
                    </v-col>
				</v-form>
			</v-card>
        </v-dialog>
     </v-col>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import { 
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, 
    Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, 
    HorizontalRule, History 
} from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import axios  from 'axios'
import Vue from 'vue'

    export default {
        name: 'DireccionesWebCommerce',
        components:{
            TiptapVuetify
        },
        data: () => ({
            dialog:false,
            dialogSede:false,
            overlay:false,
            sede:null,
            domain: process.env.VUE_APP_NODE_ENV === "production" ? process.env.VUE_APP_API_URL_PROD : process.env.VUE_APP_API_URL_DEVEL,
            sedes:[],
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading, {
                        options: {
                            levels: [1, 2, 3,4, 5, 6]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            requiredRule:[
                v => !!v || 'El campo es requerido'
            ],
        }),
        computed: {

            ...mapState('master',['loadingBtn','paramAlertQuestion','url','token'])

        },
        methods: {
            
            ...mapActions('master',['errorRequest','requestApi','alertNotification']),

            ...mapMutations('master',['setUrl','setLoadingBtn','setTitleToolbar']),

            storeSede(){

                this.setLoadingBtn(true)
                this.setUrl('administracion')
                this.requestApi({
                    method: 'POST',
                    data :{
                        typeAction: 'storeSede',
                        contentId: this.content_id_sede,
                        sede: this.sede
                    }
                }).then(res =>{

                    console.log(res)
                    this.getSedes()
                    this.close()
                    this.setLoadingBtn(false)
                    this.alertNotification({
                        param:{
                            html: res.data.res.msg
                        }
                    })
                    

                }).catch(()=>{
                    
                    this.setLoadingBtn(false)

                })

            },

            close(){

                this.dialogSede  = false
                this.sede = null

            },

            getSedes(){

                this.setUrl('administracion')
                this.requestApi({
                    method: 'GET',
                    data :{
                        typeList: 'dataWeb',
                        all:false
                    }
                }).then(res =>{
                
                    this.sedes = res.data._embedded.administracion.filter(e => e.content_type_id == 'OWNER_SEDE').map(obj=>{ 

                        const  { content_id,content_name } = obj
                        let ctnId = content_id

                        let services = res.data._embedded.administracion.filter(e => e.owner_content_id == content_id).map(ele => {
                   
                            const  { content_id, service_name, content_name, extra, description } = ele

                            return {
                                owner_content_id: ctnId,
                                content_id:content_id,
                                sedeImg: content_name,
                                service: service_name,
                                sedeUrlMaps:extra,
                                sedeDescription: description,
                            }
                            
                        })

                        if(!services.length){

                            services.push({
                                owner_content_id: ctnId,
                                content_id:null,
                                sedeImg: null,
                                sedeName: content_name,
                                sedeUrlMaps:null,
                                sedeDescription: null,
                            })

                        }
                            
                        return {
                            content_id_sede: content_id,
                            sedeName: content_name,           
                            services,
                        }

                    })

                }).then(()=>{
                    
                })

            },

            storeDataSede(_service,x){
                console.log(_service)
                if(!this.$refs.form_sedes[x].validate())
				    return
                
                this.overlay =true

                const { content_id, sedeImg, service, sedeUrlMaps, sedeDescription, owner_content_id } = _service
                    
                this.setUrl('administracion')
                
                let formData = new FormData();
               
                formData.append('typeAction', 'storeDataSede')
                formData.append('urlFiles', process.env.VUE_APP_API_URL_FILES_IMG_WEBCOMMERCE)
                formData.append('service_name', service)
                formData.append('extra', sedeUrlMaps)
                formData.append('description', sedeDescription)
                formData.append('owner_content_id', owner_content_id)
                
                if(sedeImg != null)
                    formData.append(0,sedeImg)

                if(content_id != null)
                    formData.append('content_id', content_id)
               
                axios.post(
                    this.url,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer '+ this.token
                        }
                    }
                ).then(res=>{
                  
                    this.alertNotification({
                        param:{
                            html: res.data.res.msg
                        }
                    })
                  
                    this.overlay =false
                    this.getSedes()

                }).catch(err =>{

                    const {status} = err.response

                    if(status === 500 || status === 422){
                        let response = err.response;
                        this.errorRequest({
                            data : {
                                datos: response.data.errors,
                                status : status,
                                response: response
                            }
                        })
                    }

                    this.overlay= false

                }).then(()=>{

                    this.overlay =false

                })
          
            },
            
            setFile(event,indexSede, indexService){
                
                this.sedes[indexSede].services[indexService].sedeImg = typeof event != 'undefined' && event != null ? event : null

            },

            addService({content_id_sede},i){

                this.sedes[i].services.unshift({
                    owner_content_id: content_id_sede,
                    content_id:null,
                    sedeImg: null,
                    sedeName: null,
                    sedeUrlMaps:null,
                    sedeDescription: null,
                })

            },

            deleteDataSede(_service,i,x){

                if(_service.content_id == null){
                    
                    let index = this.sedes[i].services.indexOf(this.sedes[i].services[x])
                    this.sedes[i].services.splice(index,1)

                }else{

                    Vue.swal({
                        html: "Esta seguro eliminar el servicio?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Cerrar",
                        ...this.paramAlertQuestion,
                    }).then((result) => {

                        if (result.isConfirmed) {

                            this.overlay=true
                            this.setUrl('administracion')
                            this.requestApi({
                                method: 'POST',
                                data :{
                                    typeAction: 'deleteDataSede',
                                    contentId: _service.content_id,
                                }
                            }).then(res =>{

                                console.log(res)
                                let index = this.sedes[i].services.indexOf(this.sedes[i].services[x])
                                this.sedes[i].services.splice(index,1)
                               
                                this.overlay=false
                                this.alertNotification({
                                    param:{
                                        html: res.data.res.msg
                                    }
                                })
                                

                            }).catch(()=>{
                                
                                this.overlay=false

                            })

                        }

                    })

                }

            },

            delteSede(sede,i){
              
                Vue.swal({
                    html: "Esta seguro eliminar la sede?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cerrar",
                    ...this.paramAlertQuestion,
                }).then((result) => {

                    if (result.isConfirmed) {

                        this.overlay=true
                        this.setUrl('administracion')
                        this.requestApi({
                            method: 'POST',
                            data :{
                                typeAction: 'deleteSede',
                                contentId: sede.content_id_sede,
                            }
                        }).then(res =>{

                            let index = this.sedes.indexOf(sede)
                            this.sedes.splice(index,1)
                            
                            this.overlay=false
                            this.alertNotification({
                                param:{
                                    html: res.data.res.msg
                                }
                            })

                        }).catch(()=>{
                            
                            this.overlay=false

                        })

                    }

                })

            }

        },
        created(){

            this.getSedes()

        }


    }

</script>